<template>
  <article id="wrap">

    <router-view :news="news"></router-view>

  </article>
</template>

<script>
export default {
  name: 'NewsParent',
  components: {},
  data () {
    return {
      news: []
    }
  },
  created () {
    this.initialize();
  },
  methods: {
    async initialize () {
      const response = await this.$http.get('news');
      this.news = response.data.data;
    }
  }
}
</script>
